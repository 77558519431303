  <template>
  <div>
    <v-card
      elevation=0
      class="bordered-item"
    >
      <v-card-text>
        <user-picker
          :selection.sync="creator"
          :readonly="true"
          document-types="users"
        >
          <template v-slot:prepend>
            <span>{{$t('t.Creator')}}</span>
          </template>
        </user-picker>
      </v-card-text>
    </v-card>
    <v-card
      elevation=0
      class="bordered-item"
    >
      <v-card-text>
        <span>{{$t('t.ContactsToNotifyOnCreateClose')}}</span>
        <user-picker
          :selection.sync="confirmations"
          :scope="accountScope"
          :document-types="['users', 'account-contacts']"
          :readonly="!promise.canUpdate"
          :placeholder="`${$t('t.AddContact')}...`"
          :external-user-cache-type="externalUserCacheType"
          has-email
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <span>{{$t('t.NoConfirmationContactsAvailables')}}</span>
              </v-list-item-content>
            </v-list-item>
          </template>
        </user-picker>
      </v-card-text>
    </v-card>
    <v-card
      elevation=0
      class="bordered-item"
    >
      <v-card-text>
        <v-row dense>
          <v-col class="d-flex align-baseline">
            <span>{{$t('t.ContactsToNotifyAt')}}</span>
            <v-text-field
              type="number"
              class="mx-1 days-field"
              dense
              hide-details
              :disabled="!canUpdateNotification"
              :rules="[daysBeforeNotificationIsValid]"
              v-model="daysBeforeDueDate"
            />
            <span>{{$t('t.DayOfTargetDate')}}</span>
            <span
              v-if="promise.isReminderSent && promise.reminders.length"
              class="ml-10"
            >
              <v-icon
                color="success"
                class="mr-1"
              >{{$icon("i.Checked")}}</v-icon>
              {{$t("t.IsReminderSent")}}
            </span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <user-picker
              :selection.sync="reminders"
              :scope="accountScope"
              :document-types="['users', 'account-contacts']"
              :readonly="!canUpdateNotification"
              :placeholder="`${$t('t.AddContact')}...`"
              :external-user-cache-type="externalUserCacheType"
              has-email
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <span>{{$t('t.NoConfirmationContactsAvailables')}}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </user-picker>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { CacheType } from '@/wasm/pkg'
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    UserPicker: () => import('@/components/user-picker')
  },
  data () {
    return {
      externalUserCacheType: CacheType.PromiseNotificationRecipientRef
    }
  },
  computed: {
    accountScope () {
      return Scope.account(this.promise.accountId)
    },
    canUpdateNotification () {
      return this.promise.canUpdate && !this.promise?.isReminderSent && !this.promise?.status?.id && this.promise?.installments.every(i => !i.paidDate)
    },
    confirmations: {
      get () {
        return this.promise.confirmations
      },
      set (confirmations) {
        this.$emit('update:promise', Object.assign({}, this.promise, { confirmations }))
      }
    },
    creator () {
      return { id: this.promise.createBy }
    },
    daysBeforeDueDate: {
      get () {
        return this.promise.daysBeforeDueDate ?? 1
      },
      set (daysBeforeDueDate) {
        if (typeof daysBeforeDueDate === 'string') {
          daysBeforeDueDate = parseInt(daysBeforeDueDate)
        }
        this.$emit('update:promise', Object.assign({}, this.promise, { daysBeforeDueDate }))
      }
    },
    reminders: {
      get () {
        return this.promise.reminders
      },
      set (reminders) {
        this.$emit('update:promise', Object.assign({}, this.promise, { reminders }))
      }
    },
    daysBeforeNotificationIsValid () {
      if (!this.reminders.length) {
        return true
      }
      return this.promise.isReminderSent || !this.promise.canUpdate || !this.promise.installments[0].targetDate || (
        this.promise.daysBeforeDueDate >= 0 &&
        this.moment(this.promise.installments[0].targetDate).startOf('day').diff(this.moment().startOf('day'), 'days') >= this.promise.daysBeforeDueDate
      )
    }
  },
  props: {
    promise: Object
  }
}
</script>

<style lang="stylus" scoped>
.days-field
  max-width 2em
</style>
